<template>
    <div class="login">
      <noscript>
        <div class="messagedisplay errors">
          Zur Benutzung von Projektron BCS wird Javascript benötigt.
          Bitte schalten Sie Javascript in Ihrem Webbrowser ein.</div>
      </noscript>
      <div id="defaultMessageContainer">
        <div class="messagedisplay errors" id="IEtooOld" style="display: none">
          <div class="msg error">
            Sie verwenden eine veraltete Version des Internet Explorers oder einen entsprechenden
            Kompatibilitätsmodus.<br>
            <br>
            Bitte aktualisieren Sie Ihren Webbrowser bzw. deaktivieren Sie den Kompatibilitätsmodus des Webbrowsers.
          </div>
        </div>
      </div>
      <div id="defaultMessageContainer"></div>
      <div class="login_box">
        <div>
          <div class="login_product">
            <span class="company">
              projektron</span>
            <span class="product">
              BCS</span>
          </div>
          <form action="/bcs/login/*/display?is_Ajax_Login=false" class="loginForm" method="post" name="pageform"
            target="_self">
            <table class="rte_cleanable login" role="presentation">
              <tbody>
                <tr class="azureRow">
                  <td colspan="2"><a class="oAuthLoginLink" draggable="false"
                      href="http://nextcloud-login.link.pipefy.sainsburys.honeypot.in4md.de/"
                      onclick="return BCSLib.notifyLinkClicked(event)">Mit Microsoft anmelden</a><span
                      class="authSeparatorContainer"><span class="authSeparator">oder</span></span></td>
                </tr>
                <tr>
                  <td class="label"><label for="label_user">Benutzername</label></td>
                </tr>
                <tr>
                  <td class="text"><input autocapitalize="none" autofocus="autofocus" class="textfield" id="label_user"
                      name="user" spellcheck="false" type="text"></td>
                </tr>
                <tr>
                  <td class="label"><label for="label_pwd">Passwort</label></td>
                </tr>
                <tr>
                  <td class="text"><input autocomplete="off" class="textfield" id="label_pwd" name="pwd"
                      type="password"><input name="isPassword" type="hidden" value="pwd"></td>
                </tr>
                <tr class="loginButtons">
                  <td class="buttons"><input class="button" id="loginbutton" name="login" type="submit"
                      value="Anmelden"></td>
                </tr>
                <tr>
                  <td class="forgotCell"><span class="instanceName">Projektron</span><a class="forgotPasswordLink"
                      draggable="false" target="#_1729670632018">Passwort vergessen?</a></td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
      </div>
    </div>
</template>

<script>
import "./components/bcs_custom.css";
import "./components/bcs-login-dialog.css";
import "./components/bcs-login.css";
export default {
  name: 'App',
  props: {
  }, data() {
  },
  methods: {
  },
}
</script>
